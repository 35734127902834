import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem } from '@app/s1';
import { ISite } from '@app/shared/models/models';
import { SitesService, ISitesSearchParams } from '@app/shared/services/sites.service';

@Component({
  selector: 'mdm-input-site-select',
  templateUrl: './input-site-select.component.html',
  styleUrls: ['./input-site-select.component.scss']
})
export class InputSiteSelectComponent implements OnInit {

  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();
  @Input() control: UntypedFormControl;
  @Input() set idRegion(newValue: number) {
    this.regionSelected = newValue != null
    this.control.patchValue(null);
    this.getSites(newValue)
  }
  @Input() bindAllObject: boolean = true;
  @Input() showAll: boolean = false
  @Input() placeholder: string = '';

  regionSelected: boolean = false
  sitesList: IS1InputSelectItem[];
  isAdminOrSuper: boolean;

  constructor(private sitesService: SitesService, public settingsService: SettingsService) { 
    this.isAdminOrSuper = this.settingsService.isSuperAdmin() || this.settingsService.isAdmin();
  }
  
  ngOnInit(): void {
    if(this.settingsService.isOperatore()) {
      this.idRegion = this.settingsService.getRegion()?.code ? parseInt(this.settingsService.getRegion()?.code) : null;
    }
  }

  private getSites(idRegion?: number, ui: boolean = true) {

    const params: ISitesSearchParams = {
      code: null,
      name: null,
      idCompany: this.settingsService.getCompany().code,
      idRegion: idRegion
    }

    this.sitesService.getSites(params, false).subscribe(sites => {
      this.sitesList = sites.map(site => { 
        return { code: site.id, label: site.name } 
      });
      this.onLoadList.emit(this.sitesList);
    })

  }

}
