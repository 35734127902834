import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';

import { SettingsService } from '../../core/settings/settings.service';
import { ThemesService } from '../../core/themes/themes.service';
import { TranslatorService } from '../../core/translator/translator.service';

import { CompaniesService } from '@app/shared/services/companies.service';
import { IS1InputSelectItem , S1UIService } from '@app/s1';
import { Router } from '@angular/router';

declare var $: any;

@Component({
    selector: 'app-rightsidebar',
    templateUrl: './rightsidebar.component.html',
    styleUrls: ['./rightsidebar.component.scss']
})
export class RightsidebarComponent implements OnInit, OnDestroy {

    selectedLanguage: string;
    companiesList: IS1InputSelectItem[];

    constructor(public settings: SettingsService, public themes: ThemesService, public translator: TranslatorService, public elem: ElementRef , public companiesService: CompaniesService, private ui: S1UIService, public router: Router) {
        this.selectedLanguage = this.getLangs()[0].code;
    }

    ngOnInit() {
        this.anyClickClose();

        if( this.settings.isSuperAdmin()) {
            this.getCompanies();
        }
        
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
    }

    anyClickClose() {
        document.addEventListener('click', this.checkCloseOffsidebar, false);
    }

    checkCloseOffsidebar = e => {
        const contains = (this.elem.nativeElement !== e.target && this.elem.nativeElement.contains(e.target));
        if (!contains) {
            this.settings.setLayoutSetting('offsidebarOpen', false);
        }
    }

    private getCompanies() {

        this.companiesService.getCompanies(null, false).subscribe(companies => {
          this.companiesList = companies.map(company => { 
            return { code: company.id, label: company.name } 
          });
          if( !this.settings.getActiveFiltersOfSidebar() ) {
            this.selectedFilter( 'company' , this.companiesList[0] );
          }
        })
    
    }

    selectedFilter( key: string , value  , skipAlert: boolean = true) {
        if( this.settings.getFilterOfSidebarByName('company') ) {
            this.settings.setFilterFromSidebar( key , value );
            this.settings.onSidebarFilterChange();
            this.toggleSidebar();
            
        } else {
            this.settings.setFilterFromSidebar( key , value );
            this.settings.onSidebarFilterChange();
        }
        this.router.navigate(['home']);
        
    }

    onFilterClick() {
        this.settings.onSidebarFilterChange();
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.checkCloseOffsidebar);
    }

    private toggleSidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

}
