<div class="row">
  <div class="col text-center">
    <span class="font-weight-bold my-2">{{ 'audits.audit.fields.picture'+index | translate }}</span>
  </div>  
</div>

<ng-container *ngIf="readonly">

  <div class="row" *ngIf="!pictureSrc && !pictureImage"> <!-- NO picture-->
    <div class="col text-center">
      <div class="col text-center">
        <p class="mt-2">{{ 'base.notpresent' | translate }}</p>
      </div> 
    </div>  
  </div>

  <div class="row" *ngIf="pictureSrc">
    <div class="col">
      <img class="img-fluid img-thumbnail" [src]="pictureSrc" (load)="pictureImage = loadBackgroundImage($event.target, pictureSrc, false)">
      <div class="img-fluid img-thumbnail preview" [class.d-none]="true" *ngIf="pictureImage">
        <ng-opendraw extOpenDraw
          [backgroundImage]="pictureImage"
          [canWidth]='pictureImage.width'
          [canHeight]='pictureImage.height'
          [eraser]="false"
          [fillShape]="false"
          [lineWidth]="pictureLineWidth"
          [lineColor]="pictureLineColor"
          [drawStyle]="pictureDrawStyle"
          [eraser]="pictureEraser"
          [allowedDeviceType]="allowedDeviceType"
          [commandObs]='pictureCmd$'
          (outputEvent)="pictureSrc = processResult($event)"
          (errorEvent)="processError($event)"
        ></ng-opendraw>
      </div>
    </div>  
  </div>
</ng-container>



<ng-container *ngIf="!readonly">

  <div class="row" *ngIf="!pictureSrc && !pictureImage"> <!-- NO picture-->
    <div class="col text-center">
      <div class="col text-center">
        <p class="mt-2">{{ 'base.notpresent' | translate }}</p>
        <label for="upload" class="mt-2 file-upload">
          <s1-button [type]="s1ButtonType.Browse" [type]="s1ButtonType.Upload"></s1-button>
          <input id="upload" type="file" ng2FileSelect [uploader]="uploader" (onFileSelected)="fileSelected()" (change)="fileChanged($event)"/>
          <!-- <label class="mx-2">(max {{ maxSize/1024/1024 }} MB)</label> -->
          <p class="mt-2 text-danger" *ngIf="exeecedSizeError">{{ 'msg.file_size_exceeds' | translate }}</p>
          <p class="mt-2 text-danger" *ngIf="imageFormatError">{{ 'msg.file_format_error' | translate }}</p>
        </label>
      </div> 
    </div>  
  </div>
  
  <div class="row" *ngIf="pictureSrc && pictureDeletable">  <!-- YES picture, YES deleting -->
    <div class="col text-center">
      <p class="mt-4 text-danger"><em class="mr-2 fa fas fa-exclamation"></em>{{ 'audits.audit.popup.confirmDelete' | translate }}</p>
      <p class="mt-2">{{ 'base.or' | translate }}
        <s1-button class="ml-2" (onClick)="pictureDeletable = false" [type]="s1ButtonType.Cancel" [label]="'audits.audit.popup.undoDeletePicture'"></s1-button>
      </p>
    </div>  
  </div>
  
  <div class="row" *ngIf="pictureSrc && pictureUpdatable">  <!-- YES picture, YES updating -->
    <div class="col text-center">
      <label for="upload" class="mt-2 file-upload">
        <s1-button [type]="s1ButtonType.Browse" [type]="s1ButtonType.Upload"></s1-button>
        <input id="upload" type="file" ng2FileSelect [uploader]="uploader" (onFileSelected)="fileSelected()" (change)="fileChanged($event)"/>
        <!-- <label class="mx-2">(max {{ maxSize/1024/1024 }} MB)</label> -->
        <p class="mt-2 text-danger" *ngIf="exeecedSizeError">{{ 'msg.file_size_exceeds' | translate }}</p>
        <p class="mt-2 text-danger" *ngIf="imageFormatError">{{ 'msg.file_format_error' | translate }}</p>
      </label>
      <p class="mt-2">{{ 'base.or' | translate }}
        <s1-button class="ml-2" (onClick)="pictureUpdatable = false" [type]="s1ButtonType.Cancel" [label]="'audits.audit.popup.undoDeletePicture'"></s1-button>
      </p>
    </div> 
  </div>
  
  <ng-container *ngIf="pictureSrc && !pictureDeletable && !pictureUpdatable">  <!-- YES picture, NO deleting, updating -->
    <div class="row">
      <div class="col text-right mt-2 mb-1">
        <s1-button *ngIf="pictureAction == 'crop'" (onClick)="pictureAction = 'draw'; switchAction(pictureAction)" [type]="s1ButtonType.Normal" [label]="'base.draw'" icon="icon-pencil" class="mr-2"></s1-button>
        <s1-button *ngIf="pictureAction == 'draw'" (onClick)="pictureAction = 'crop'; switchAction(pictureAction)" [type]="s1ButtonType.Normal" [label]="'base.crop'" icon="icon-frame" class="mr-2"></s1-button>
        <s1-button (onClick)="pictureUpdatable = true; updatePicture()" [type]="s1ButtonType.Cancel" icon="icon-camera" label="base.change" class="mr-2"></s1-button>
        <s1-button  *ngIf="pictureSrcOriginal" (onClick)="pictureDeletable = true; updatePicture()" [type]="s1ButtonType.Delete"></s1-button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col text-center mb-1">
        <ng-container *ngIf="pictureAction == 'draw'">
          
          <div class="btn-group mr-2" dropdown>
            <a class="dropdown-toggle pointer bg-gray-light p-1" dropdownToggle><em style="font-size: 24px;" class="fa-2x fas fa-circle" [ngClass]="pictureSelectionColor"></em></a>
            <div *dropdownMenu class="dropdown-menu bg-gray-light" style="min-width: 50px">
                <a class="dropdown-item pointer" (click)="changeColor('text-black')"><em class="fa-2x fas fa-circle text-black"></em></a>
                <a class="dropdown-item pointer" (click)="changeColor('text-white')"><em class="fa-2x fas fa-circle text-white"></em></a>
                <a class="dropdown-item pointer" (click)="changeColor('text-danger')"><em class="fa-2x fas fa-circle text-danger"></em></a>
                <a class="dropdown-item pointer" (click)="changeColor('text-yellow')"><em class="fa-2x fas fa-circle text-yellow"></em></a>
            </div>
          </div>
          <div class="btn-group mr-2" dropdown>
            <a class="dropdown-toggle pointer bg-gray-light p-2" dropdownToggle><span>{{pictureSelectionDrawStyle}}</span></a>
            <div *dropdownMenu class="dropdown-menu bg-gray-light" style="min-width: 50px">
                <a class="dropdown-item pointer" (click)="changeDrawLine(drawStyle.LINE)">{{'base.line' | translate }}</a>
                <a class="dropdown-item pointer" (click)="changeDrawLine(drawStyle.ELLIPSE)">{{'base.circle' | translate }}</a>
                <a class="dropdown-item pointer" (click)="changeDrawLine('eraser')">{{'base.eraser' | translate }}</a>
            </div>
            <s1-button (onClick)="resetPicture()" [onlyIcon]="false" label="base.reset" [type]="s1ButtonType.Cancel" icon="fas fa-arrow-left" class="ml-1"></s1-button>
          </div>
        </ng-container>
        <ng-container *ngIf="pictureAction == 'crop'">
          <span>{{ 'base.cropConfirm' | translate }}</span>
          <s1-button (onClick)="pictureSrc = switchAction('draw', pictureCropped); pictureAction = 'draw';" [onlyIcon]="true" [type]="s1ButtonType.Check" class="ml-2"></s1-button>
        </ng-container>
      </div>
    </div>
  </ng-container>
  
  <div class="row" *ngIf="pictureSrc && !pictureDeletable && !pictureUpdatable && pictureAction == 'draw'">  <!-- YES picture, drawing -->
    <div class="col">
      <img class="img-fluid img-thumbnail" [src]="pictureSrc" (load)="pictureImage = loadBackgroundImage($event.target, pictureSrc)">
      <div class="img-fluid img-thumbnail preview" [class.pointer-eraser]="pictureEraser" [class.pointer-circle]="pictureDrawStyle == drawStyle.ELLIPSE" [class.d-none]="pictureDeletable" *ngIf="pictureImage">
        <ng-opendraw extOpenDraw
          [backgroundImage]="pictureImage"
          [canWidth]='pictureImage.width'
          [canHeight]='pictureImage.height'
          [eraser]="false"
          [fillShape]="false"
          [lineWidth]="pictureLineWidth"
          [lineColor]="pictureLineColor"
          [drawStyle]="pictureDrawStyle"
          [eraser]="pictureEraser"
          [allowedDeviceType]="allowedDeviceType"
          [commandObs]='pictureCmd$'
          (outputEvent)="pictureSrc = processResult($event)"
          (errorEvent)="processError($event)"
        ></ng-opendraw>
      </div>
    </div>  
  </div>
  
  <div class="row" *ngIf="pictureSrc && !pictureDeletable && !pictureUpdatable && pictureAction == 'crop'">  <!-- YES picture, cropping -->
    <div class="col">
      <image-cropper *ngIf="pictureImage"
          [imageURL]="pictureSrc"
          [maintainAspectRatio]="false"
          format="jpeg"
          (imageCropped)="pictureCropped = imageCropped($event)"
      ></image-cropper>
    </div>
  </div>

</ng-container>

