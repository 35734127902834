import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';

import { IS1InputSelectItem } from '@app/s1';
import { RegionsService, IRegionsSearchParams } from '@app/shared/services/regions.service';

@Component({
  selector: 'mdm-input-region-select',
  templateUrl: './input-region-select.component.html',
  styleUrls: ['./input-region-select.component.scss']
})
export class InputRegionSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() set idCompany(newValue: number) {
    this.companySelected = newValue != null
    this.getRegions(newValue)  
  }

  @Input() showAll: boolean = false;
  @Input() bindAllObject: boolean = true;
  @Input() placeholder: string = '';
  @Output() onSelectChange = new EventEmitter();

  companySelected: boolean = false;
  regionsList: IS1InputSelectItem[];
  isAdminOrSuper: boolean;
  
  constructor(private regionsService: RegionsService, public settingsService: SettingsService) { 
    this.isAdminOrSuper = this.settingsService.isSuperAdmin() || this.settingsService.isAdmin();
   }

  ngOnInit(): void {
    //this.getRegions()
  }

  private getRegions(idCompany?: number, ui: boolean = true) {

    const params: IRegionsSearchParams = {
      code: null,
      name: null,
      idCompany: idCompany
    }

    this.regionsService.getRegions(params, false).subscribe(regions => {
      this.regionsList = regions.map(region => { 
        return { code: region.id, label: region.name } 
      })
    })

  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
