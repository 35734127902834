<ng-container *ngIf="isAdminOrSuper || !settingsService.getSite()?.code">
  <s1-input-select [readonly]="!(showAll || regionSelected)" class="col-6 mb-2 fix-margin" [control]="control"  [bindAllObject]="bindAllObject"  [itemsList]="sitesList" [label]="'fields.input.site'" [labelGrouped]="false" [placeholder]="placeholder"></s1-input-select>
</ng-container>

<ng-container *ngIf="!isAdminOrSuper && settingsService.getSite()?.code">
  <s1-input-label class="col-6 mb-2 fix-margin" [label]="'fields.input.site'" [grouped]="false">
    <input class="form-control" type="text" [readonly]="true" value="{{settingsService.getSite()?.label}}"/>
  </s1-input-label>
</ng-container>


