
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'fa fa-home',
    roles: []
};

const SampleChecks = {
  translate: 'menu.samplechecks',
  link: '/checks/sample',
  icon: 'fas fa-tshirt',
  roles: ['OPERATOR','ADMIN','SUPERADMIN']
};

const KpiSampleChecks = {
  translate: 'menu.kpisamplechecks',
  link: '/checks/kpisample',
  icon: 'fas fa-tachometer-alt',
  roles: ['OPERATOR','ADMIN','SUPERADMIN']
};

const SampleOperationsChecks = {
  translate: 'menu.samplechecksOperations',
  link: '/checks/sampleoperations',
  icon: 'fas fa-tshirt',
  roles: ['OPERATOR','ADMIN','SUPERADMIN','COLLAUDO_CAMPIONARIO_OPERATIONS']
};

const KpiSampleOperationsChecks = {
  translate: 'menu.kpisamplechecksOperations',
  link: '/checks/kpisampleoperations',
  icon: 'fas fa-tachometer-alt',
  roles: ['OPERATOR','ADMIN','SUPERADMIN','COLLAUDO_CAMPIONARIO_OPERATIONS']
};

const Audits = {
  translate: 'menu.audits',
  link: '/audits/audit',
  icon: 'fas fa-expand',
  roles: ['OPERATOR','ADMIN','SUPERADMIN']
};

const Users = {
  translate: 'menu.users',
  link: '/user',
  icon: 'fa fa-user',
  roles: ['ADMIN','SUPERADMIN']
};

const CommesseManuali = {
  translate: 'menu.commesseManuali',
  link: '/commesse/commessemanuali',
  icon: 'fas fa-shopping-cart',
  roles: ['OPERATOR','ADMIN','SUPERADMIN']
};

export const menu = [
  //Home,
  SampleChecks,
  KpiSampleChecks,
  SampleOperationsChecks,
  KpiSampleOperationsChecks,
  Audits,
  CommesseManuali,
  Users
];
