import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem } from '@app/s1';

@Component({
  selector: 'mdm-input-user-role-select',
  templateUrl: './input-user-role-select.component.html',
  styleUrls: ['./input-user-role-select.component.scss']
})
export class InputUserRoleSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;

  rolesList: IS1InputSelectItem[];

  constructor(private settingsService: SettingsService) { 
    this.rolesList = [];
    if(settingsService.isSuperAdmin()) {
      this.rolesList.push({ code: 'SUPERADMIN', label: 'Superadmin' });
    }
    this.rolesList.push({ code: 'ADMIN', label: 'Admin' });
    this.rolesList.push({ code: 'OPERATOR', label: 'Operatore' });
    this.rolesList.push({ code: 'COLLAUDO_CAMPIONARIO_OPERATIONS', label: 'Collaudo campionario operations' });
  }

  ngOnInit(): void {
  }

}
