import { Directive, ElementRef, Host, Optional, Self } from '@angular/core';
import { DrawStyle, NgOpendrawComponent } from '@erospingani1/ng-opendraw';
declare var $: any;

@Directive({
    selector: '[extOpenDraw]'
})
export class ExtOpenDrawDirective {

    constructor(
        @Host() @Self() @Optional() public hostNgOpendrawComponent : NgOpendrawComponent) {
        // Now you can access specific instance members of host directive
        let host = (<any>hostNgOpendrawComponent);
        host.ctxFgTemp = null;
        // also you can override specific methods from original host directive so that this specific instance uses your method rather than their original methods.
        host.draw = (x, y) => {
            if (host.lastPointerX && host.lastPointerY) {
                host.ctxFg.beginPath();
                if (host._eraser) {
                    host.ctxFg.save();
                    host.ctxFg.globalCompositeOperation = "destination-out";
                    host.ctxFg.strokeStyle = "rgba(0,0,0,1)";
                    host.ctxFg.fillStyle = "rgba(0,0,0,1)";
                }
                else {
                    host.ctxFg.strokeStyle = host._lineColor;
                    host.ctxFg.fillStyle = host._lineColor;
                }
                host.ctxFg.lineWidth = host._lineWidth;
                host.ctxFg.lineCap = "round";
                host.ctxFg.lineJoin = "round";
                if (host.drawStyle === DrawStyle.LINE) {
                    host.ctxFg.moveTo(host.lastPointerX, host.lastPointerY);
                    host.ctxFg.lineTo(x, y);
    
                    var headlen = 15; // length of head in pixels
                    var dx = x - host.lastPointerX;
                    var dy = y - host.lastPointerY;
                    var angle = Math.atan2(dy, dx);
                    host.ctxFg.lineTo(x - headlen * Math.cos(angle - Math.PI / 6), y - headlen * Math.sin(angle - Math.PI / 6));
                    host.ctxFg.moveTo(x, y);
                    host.ctxFg.lineTo(x - headlen * Math.cos(angle + Math.PI / 6), y - headlen * Math.sin(angle + Math.PI / 6));         
                }
                else if (host.drawStyle === DrawStyle.NORMAL) {
                    host.ctxFg.moveTo(host.lastPointerX, host.lastPointerY);
                    host.ctxFg.lineTo(x, y);       
                }
                else if (host.drawStyle === DrawStyle.RECTANGLE) {
                    /** @type {?} */
                    let startX;
                    /** @type {?} */
                    let startY;
                    /** @type {?} */
                    let endX;
                    /** @type {?} */
                    let endY;
                    if (host.lastPointerX < x) {
                        startX = host.lastPointerX;
                        endX = x - startX;
                    }
                    else {
                        startX = x;
                        endX = host.lastPointerX - startX;
                    }
                    if (host.lastPointerY < y) {
                        startY = host.lastPointerY;
                        endY = y - startY;
                    }
                    else {
                        startY = y;
                        endY = host.lastPointerY - startY;
                    }
                    host.ctxFg.rect(startX, startY, endX, endY);
                }
                else if (host.drawStyle === DrawStyle.CIRCLE) {
                    /** @type {?} */
                    let radius = Math.pow(Math.abs(y - host.lastPointerY), 2);
                    radius += Math.pow(Math.abs(x - host.lastPointerX), 2);
                    radius = Math.sqrt(radius);
                    host.ctxFg.arc(host.lastPointerX, host.lastPointerY, radius, 0, 2 * Math.PI);
                }
                else if (host.drawStyle === DrawStyle.ELLIPSE) {
                    host.ctxFg.ellipse(host.lastPointerX, host.lastPointerY, Math.abs(x - host.lastPointerX), Math.abs(y - host.lastPointerY), Math.PI, 0, Math.PI * 2);
                }
                if (host.fillShape && (host.drawStyle === DrawStyle.ELLIPSE || host.drawStyle === DrawStyle.CIRCLE || host.drawStyle === DrawStyle.RECTANGLE)) {
                    host.ctxFg.fill();
                }
                else {
                    host.ctxFg.stroke();
                }
                host.ctxFg.closePath();
                if (host._eraser) {
                    host.ctxFg.restore();
                }
            }
            host.lastPointerX = x;
            host.lastPointerY = y;
        }

        host.handleDeviceDrawing = (event) => {
            event.preventDefault();
            /** @type {?} */
            let deviceType = "mouse";
            if (event.pointerType) {
                deviceType = event.pointerType;
            }
            if (!deviceType.match(host._allowedDeviceType)) {
                return;
            }
            switch (event.pointerType) {
                case 'mouse':
                case 'touch':
                    switch (event.type) {
                        case 'pointermove':
                            //draw temporarily if the style is not normal
                            if (host.drawStyle === DrawStyle.ELLIPSE || host.drawStyle === DrawStyle.CIRCLE || host.drawStyle === DrawStyle.LINE || host.drawStyle === DrawStyle.RECTANGLE) {
                                if (host.drawingActive) {
                                    host.ctxFg.putImageData(host.ctxFgTemp,0,0);
                                    let x = host.lastPointerX;
                                    let y = host.lastPointerY;
                                    host.draw(event.offsetX, event.offsetY);
                                    host.lastPointerX = x;
                                    host.lastPointerY = y;
                                }
                            }
                            if (host.drawStyle === DrawStyle.NORMAL) {
                                if (host.drawingActive) {
                                    host.draw(event.offsetX, event.offsetY);
                                }
                            }
                            break;
                        case 'pointerup':
                            if (host.drawStyle === DrawStyle.ELLIPSE || host.drawStyle === DrawStyle.CIRCLE || host.drawStyle === DrawStyle.LINE || host.drawStyle === DrawStyle.RECTANGLE) {
                                host.draw(event.offsetX, event.offsetY);
                            }
                            host.drawingActive = false;
                            host.lastPointerX = null;
                            host.lastPointerY = null;
                            break;
                        case 'pointerdown':
                            //save canvas in order to draw temporarily if the style is not normal
                            if (host.drawStyle === DrawStyle.ELLIPSE || host.drawStyle === DrawStyle.CIRCLE || host.drawStyle === DrawStyle.LINE || host.drawStyle === DrawStyle.RECTANGLE) {
                                let sx = 0;
                                let sy = 0;
                                let dx = host._canWidth;
                                let dy = host._canHeight;
                                const can = document.createElement('canvas');
                                can.width = dx - sx;
                                can.height = dy - sy;
                                const ctx = can.getContext('2d');
                                ctx.drawImage(host.canvasFg.nativeElement, sx, sy, dx, dy);
                                host.ctxFgTemp = ctx.getImageData(sx, sy, dx, dy);
                            }
    
                            host.drawingActive = true;
                            host.lastPointerX = event.offsetX;
                            host.lastPointerY = event.offsetY;
                            if (host.drawStyle === DrawStyle.NORMAL) {
                                host.draw(event.offsetX, event.offsetY);
                            }
                            break;
                        case 'pointerleave':
                            if (host.drawStyle === DrawStyle.ELLIPSE || host.drawStyle === DrawStyle.CIRCLE || host.drawStyle === DrawStyle.LINE || host.drawStyle === DrawStyle.RECTANGLE) {
                                host.draw(event.offsetX, event.offsetY);
                            }
                            host.drawingActive = false;
                            host.lastPointerX = null;
                            host.lastPointerY = null;
                            break;
                        case 'pointerout':
                            if (host.drawStyle === DrawStyle.ELLIPSE || host.drawStyle === DrawStyle.CIRCLE || host.drawStyle === DrawStyle.LINE || host.drawStyle === DrawStyle.RECTANGLE) {
                                host.draw(event.offsetX, event.offsetY);
                            }
                            host.drawingActive = false;
                            host.lastPointerX = null;
                            host.lastPointerY = null;
                            break;
                    }
                    break;
                case 'pen':
                    switch (event.type) {
                        case 'pointermove':
                            if (host.drawStyle === DrawStyle.NORMAL) {
                                if (host.drawingActive) {
                                    host.draw(event.offsetX, event.offsetY);
                                }
                            }
                            break;
                        case 'pointerdown':
                            host.drawingActive = true;
                            host.lastPointerX = event.offsetX;
                            host.lastPointerY = event.offsetY;
                            if (host.drawStyle === DrawStyle.NORMAL) {
                                host.draw(event.offsetX, event.offsetY);
                            }
                            break;
                        case 'pointerup':
                        case 'panend':
                            if (host.drawStyle === DrawStyle.ELLIPSE || host.drawStyle === DrawStyle.CIRCLE || host.drawStyle === DrawStyle.LINE || host.drawStyle === DrawStyle.RECTANGLE) {
                                host.draw(event.srcEvent.offsetX, event.srcEvent.offsetY);
                            }
                            host.drawingActive = false;
                            host.lastPointerX = null;
                            host.lastPointerY = null;
                            break;
                    }
                    break;
            }
        }
    }

}
