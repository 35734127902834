<s1-header [style]="'border-bottom-0'" id="subheader" [alignTitleCenter]="true">
    
    <!-- 
    <span center class="align-text-bottom"><em class="fa fa-industry mr-2"></em> {{ 'header.active_company' | translate: { company: activeCompany?.label } }}</span>
    Open Company selection
    <button center id="menu-toggle" class="ml-2 btn btn-oval btn-primary" type="button" (click)="menuToggle()" [hidden]="!isSuperAdmin">
        <em class="fa fa-exchange-alt mr-2"></em>
        <span>{{ 'header.change_company' | translate }}</span>
    </button>
    -->
</s1-header>