
<!-- <tr>-->
  <ng-content select="[header-columns]"></ng-content>
  <td class="text-right">
    <ng-content select="[buttons]"></ng-content>
    <button type="button" class="btn btn-inverse" (click)="expanded = !expanded">
        <i *ngIf="expanded" class="fa fa-angle-double-up" ></i>
        <i *ngIf="!expanded" class="fa fa-angle-double-down" ></i>
    </button>
  </td>
    <!--<tr>
      <td>
        <ng-content select="[expandable-content]"></ng-content>
      </td>
      
    </tr>-->

<!--</tr>
<tr *ngIf="expanded">
  <td colspan="2">
    <ng-content select="[expandable-content]"></ng-content>
  </td>
</tr>-->
<!--<tr [hidden]="!expanded" class="text-right">
  <td colspan="cols">
    <content></content>
  </td>
</tr>-->
