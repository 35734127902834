<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-secondary">
                <a href="#">
                    <img class="block-center rounded" src="assets/img/logo-big.png" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <div class="text-danger text-center" *ngIf="errorMessage">{{errorMessage}}</div>
                <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="form-validate mb-3" role="form" name="loginForm" novalidate="" >
                    <div class="form-group">
                        <div class="input-group with-focus">
                            <input class="form-control border-right-0" id="email" type="email" name="email" placeholder="{{'base.enter_username' | translate}}" formControlName="username" />
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fa fa-user"></em>
                                </span>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="loginForm.controls['username'].hasError('required') && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)">This field is required</div>
                    </div>
                    <div class="form-group">
                        <div class="input-group with-focus">
                            <input *ngIf="!safeContext" class="form-control border-right-0" id="password" type="password" name="password" placeholder="{{'base.pwd' | translate}}" formControlName="password" autocomplete="on" />
                            <input *ngIf="safeContext" class="form-control border-right-0" id="password" type="text" name="password" placeholder="{{'base.pwd' | translate}}" formControlName="password" autocomplete="on" />
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0 pointer" (click)="safeContext=!safeContext">
                                    <em *ngIf="!safeContext" class="fa fa-lock"></em>
                                    <em *ngIf="safeContext" class="fa fa-lock-open"></em>
                                </span>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="loginForm.controls['password'].hasError('required') && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">This field is required</div>
                    </div>
                    <div class="clearfix">
                        <div class="float-right">
                            <a class="text-muted" [routerLink]="'/forgot'">{{'login.forgot_pwd' | translate}}</a>
                        </div>
                    </div>
                    <button [disabled]="!loginForm.valid" class="btn btn-block btn-primary mt-4" type="submit">{{'login.login' | translate}}</button>
                </form>
            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.getAppSetting('year') }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.getAppSetting('name') }}</span>
            <br/>
            <span>{{ settings.getAppSetting('description') }}</span>
            <br/>
            <span>V. {{ settings.getAppSetting('version') }} - {{settings.getAppSetting('releaseDate') }} </span> 
        
        </div>
    </div>
</div>
