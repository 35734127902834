import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        this.menuItems = [];
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {

        let menuLoaded : Array<{
            text:string,
            link:string,
            icon: string,
            roles: Array<string>
        }> = new Array;
        
        let roleUser = localStorage.getItem("role");
        this.menuItems.forEach(menuLink => {
            var _elem = this.checkMenuRoleLevel( menuLink , roleUser );
            if( _elem ) {
                menuLoaded.push(menuLink);  
            }
        });
        return menuLoaded;
    }

    checkMenuRoleLevel( menuLink , roleUser ) {
        let resultArr : Array<{
            text:string,
            link:string,
            icon: string,
            roles: Array<string>
        }> = new Array;

        let result = this.filterMenuRole( menuLink , roleUser );
        if( result?.submenu ) {
            var _subMenu = result.submenu;
            result.submenu = [];

            for(var i = 0; i < _subMenu.length; i++ ) {
                var _item = this.filterMenuRole( _subMenu[i] , roleUser ); 
                if( _item ) {
                    result.submenu.push( _item )
                }
            }
            
        }
        return result;
    }

    filterMenuRole( menuLink , roleUser ) {
        if(menuLink.roles == null || menuLink.roles.length == 0 || (menuLink.roles.some(role => role === roleUser))) {
            return menuLink;
        }
    }

}
