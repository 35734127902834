import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TranslatorService {

    public defaultLanguage: string = 'it';
    private languageInUse: string = 'it';
    languageChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private availablelangs = [
        { code: 'en', text: 'English' },
        { code: 'it', text: 'Italian' }
    ];

    constructor(public translate: TranslateService) {

        this.defaultLanguage = this.browserLanguage();

        // Default language in caso non ci sia valore nel local storage
        if (!translate.getDefaultLang())
            translate.setDefaultLang(this.defaultLanguage);

        // Setto come lingua in uso quella salvata nel local storage
        this.useLanguage(this.getFavouriteLanguage())

    }

    browserLanguage() {
        if(navigator.language.includes("it"))
            return 'it';
        return this.defaultLanguage;
    }

    useLanguage(newLang: string = null) {

        // Controllo che la lingua che si vuole settare sia un codice lingua tra quelli disponibili altrimenti uso quello di default
        if (this.availablelangs.find(lang => lang.code == newLang)) {
            this.languageInUse = newLang
        } else {
            this.languageInUse = this.translate.getDefaultLang()
        }

        // Setto la nuova lingua in uso e la salvo come preferita
        this.translate.use(this.languageInUse)
        this.setFavouriteLanguage(this.languageInUse)
        this.languageChanged.next(true)
    }

    getLanguageInUse() {
        return this.languageInUse
    }

    getAvailableLanguages() {
        return this.availablelangs;
    }

    getFavouriteLanguage():string {
        return localStorage.getItem("language");
    }

    setFavouriteLanguage(lang: string) {
        localStorage.setItem("language", lang);
    }

}
