import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampToTime'
})
export class TimestampToTimePipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {

    if(!isNaN(value)){

      let startValue = value
      const hours = Math.floor(startValue / 3600)

      let limit = args.length>0 ? parseInt(args[0]): 100;
      if (hours >= limit) {
        return "> "+limit+"h"
      }

      const hoursInSec = (hours * 3600)

      const minutes = Math.floor((startValue - hoursInSec) / 60)
      const seconds = startValue - hoursInSec - (minutes * 60)

      const hoursStr = hours > 0 ? hours + 'h ' : ''
      const minutesStr = minutes > 0 ? ('0' + minutes).substr(-2) + 'm ' : ''
      const secondsStr = ('0' + seconds).substr(-2) + 's '
      
      return hoursStr + minutesStr + secondsStr;
    }
    return;
  }

}
