<s1-input-label [label]="label" [grouped]="false">
  <ng-multiselect-dropdown
    placeholder=" "
    [formControl]="control"
    [settings]="dropdownSettings"
    [data]="options"
    (onDropDownClose)="resetLang($event)"
    (onSelect)="onItemSelect($event)"
    (onSelectAll)="onSelectAll($event)">
  </ng-multiselect-dropdown>
</s1-input-label>
