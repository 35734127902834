import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoggingService } from './log.service';
import { throwError } from 'rxjs';
import { SettingsService } from '../settings/settings.service';

import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class AppService {

  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;
  public maxTablePage = 5;

  constructor(private http: HttpClient, private logger: LoggingService, private router: Router, private settings: SettingsService) {}

  //TODO migliorare
  post(path, filters) {

    // stringa per chiamata da fare
    let queryStr = "";
    let i = 0;
    let stringAmp = "";
    // imposto i parametri del filtro
    /*if (filters != null) {
      Object.keys(filters).forEach(function (key) {
        if (filters[key] != null && filters[key].toString() !== "") {
          if (i > 0) { stringAmp = "&"; }
          queryStr = queryStr + stringAmp + key + "=" + filters[key];
          i++;
        }
      });
    }*/

    let url = environment.restBaseUrl + path //+ "?" + queryStr

    return this.http
      .post<any[]>(url, filters, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {

        this.logger.log("URL: ", url, 200)
        this.logger.log("DATA: ", filters, 200)
        this.logger.log("POST RESP: ", response, 200)

        swal.close();

        const outcome = response['outcome'];
        const data = response['data'];
        this.totalItems = data ? data['total'] : 0;

        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return data;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        console.log("Error", errorResponse)
        if (res?.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));

  }

  // Rest Items List Service
  getList(path, filters, enableLoading: boolean = true) {

    if (enableLoading) {
      swal.fire({
        title: "Loading...", //this.msgTranslation.value.loading,
        didOpen: () => {
          swal.showLoading();
        }
      });
    }

    // stringa per chiamata da fare
    let queryStr = "";
    let i = 0;
    let stringAmp = "";
    // imposto i parametri del filtro
    if (filters != null) {
      Object.keys(filters).forEach(function (key) {
        if (filters[key] != null &&   filters[key].toString() !== "") {
          if (i > 0) { stringAmp = "&"; }
          queryStr = queryStr + stringAmp + key + "=" + filters[key];
          i++;
        }
      });
    }

    // creo tutta la chiamata completa di paginazione e righe
    queryStr = environment.restBaseUrl
    + path + "?" + queryStr
    + "&paging=" + this.paging
    + "&page=" + this.page
    + "&rows=" + this.itemsPerPage;


    // clean url
    queryStr = queryStr.replace("?&", "?");

    this.logger.log("Chiamata URL lista:" + queryStr, "", 200);

    return this.http
      .get<any[]>(queryStr, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {

        if (enableLoading) {
          swal.close();
        }

        const outcome = response['outcome'];
        const data = response['data'];
        this.totalItems = data ? data['total'] : 0;

        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return data;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        console.log("Erro", errorResponse)
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // Rest Items Service: Read one element (detail)
  getElement(path) {
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // Rest Items Service: Read all REST Items
  getAll(path) {
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // get element list by data passed
  getAllFromData(path, requestData): any {
    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, requestData, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // creation of new element
  newElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE:" + response, 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return response;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // modify of an element
  editElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // modify of an element
  deleteElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  sendElement(path, elementRequest): any {

    swal.fire({
      title: "Loading", //this.msgTranslation.value.loading,
      didOpen: () => {
        swal.showLoading();
      }
    });

    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        swal.close();
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          swal.fire('Signal correctly sended', '', 'success');
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          swal.fire('Oops an error occurred sending signal', '', 'error');
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  invite(idUser: number): any {
    const path = environment.restBaseUrl + '/admin/user/invite/' + idUser;
    const elementRequest: any = {};

    // TODO: inserire la chiamata al servizio corretto!
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("token") ?? "",
        'X-Auth-Token': localStorage.getItem("token") ?? ""
      })
    };
    return httpOptions;
  }

  delete(path): any {
    path = environment.restBaseUrl + path;
    return this.http
      .delete<any[]>(path, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

}

