<div class="content-heading" [ngClass]="style">
  <div [ngClass]="alignTitleCenter ? 'flex-fill': 'flex, pr-2'">
    <s1-button *ngIf="backPath" [routerLink]="[backPath]" [type]="s1ButtonType.Back" [outline]="true"></s1-button>
    <ng-content select="[left]"></ng-content>
  </div>
  <div class="flex" [ngClass]="alignTitleCenter ? 'text-center': 'text-left'">
    {{ title | translate }}
    <ng-content select="[center]"></ng-content>
  </div>
  <div class="flex-fill text-right">
    <ng-content select="[right]"></ng-content>
  </div>
</div>
