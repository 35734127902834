import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { S1ButtonType } from '../s1-button/s1-button.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 's1-header',
  templateUrl: './s1-header.component.html',
  styleUrls: ['./s1-header.component.scss']
})
export class S1Header implements OnInit {

  @Input() title:string;
  @Input() backPath:string;
  @Input() style:string;
  @Input() alignTitleCenter:boolean = true;
  @Output() backClicked = new EventEmitter();

  s1ButtonType = S1ButtonType

  constructor() { }

  ngOnInit(): void {}

  goBack() {
    this.backClicked.emit()
  }

}
