import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from './log.service';
import { ApiService, FilterAPI } from './api.service';
import { AppService } from './base.service';
import { Subject } from 'rxjs';
//import { UnitType } from '../classes/unit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IUnitFilter {

  serialNumber: string,
  status: number,
  type: any //UnitType

}

@Injectable({
  providedIn: 'root'
})

export class FilterService {

  filterStructure: any;
  actualSelection: any;

  filterState = new Subject();

  unitFilter: IUnitFilter;

  constructor(private appService: AppService, private logger: LoggingService, private router: Router, private api: ApiService) {

    //this.reset()

  }

  resetUnitFilter() {
    this.unitFilter = {
      serialNumber: null,
      status: 0,
      type: null
    }
  }

  getUnitFilter(): IUnitFilter {
    return JSON.parse(localStorage.getItem("unitFilter"))
  }

  setUnitFilter(filter: IUnitFilter) {
    this.unitFilter = filter
    localStorage.setItem("unitFilter", JSON.stringify(this.unitFilter))
  }

  reset() {

    let structure = JSON.parse(localStorage.getItem("filterStructure"))
    let actualSelection = JSON.parse(localStorage.getItem("actualFilter"))

    if (structure == null || structure.companies?.length == 0) {
      this.resetStructure()
      this.getRemoteCompanies().subscribe()
    } else {
      this.filterStructure = structure
    }

    if (actualSelection == null) {
      this.resetActualSelection()
      this.saveState()
    } else {
      this.actualSelection = actualSelection
    }

    this.preselectFilter()

    this.resetUnitFilter()

  }

  forceReset(): Observable<any> {

    this.resetStructure()
    return this.getRemoteCompanies()

  }

  saveState() {
    localStorage.setItem("filterStructure", JSON.stringify(this.filterStructure))
    localStorage.setItem("actualFilter", JSON.stringify(this.actualSelection))
  }

  updateState() {
    this.saveState()
    this.filterState.next(this.actualSelection);
  }

  resetStructure() {

    this.filterStructure = {
      companies: [],
      countries: [],
      locations: [],
      subLocations: [],
      walls: []
    }

  }

  resetActualSelection() {
    this.actualSelection = {
      company: null,
      country: null,
      location: null,
      subLocation: null,
      wall: null
    }
  }

  getRemoteCompanies(): Observable<any> {

    this.filterStructure.companies = []

    return this.appService.post(FilterAPI.data, {}).pipe(
      map(response => {

        this.filterStructure.companies = response.results

        if (this.filterStructure.companies?.length == 1) {
          this.selectCompany(this.filterStructure.companies[0])
        }
    
        this.saveState()

      })
    )

  }

  selectCompany(id) {

    this.actualSelection.company = this.filterStructure.companies?.find(company => company.id == id)
    this.filterStructure.countries = this.actualSelection.company?.countries || []
  }

  selectCountry(id) {

    this.actualSelection.country = this.filterStructure.countries?.find(country => country.id == id)
    this.filterStructure.locations = this.actualSelection.country?.locations || []

  }

  selectLocation(id) {

    this.actualSelection.location = this.filterStructure.locations?.find(location => location.id == id)
    this.filterStructure.subLocations = this.actualSelection.location?.subLocations || []

  }

  selectSubLocation(id) {

    this.actualSelection.subLocation = this.filterStructure.subLocations?.find(subLoc => subLoc.id == id)

    let filteredWalls = this.actualSelection.subLocation?.walls.filter( wall => wall.wallType == "WALL")

    this.filterStructure.walls = filteredWalls || []
    
  }

  selectWall(id) {

    this.actualSelection.wall = this.filterStructure.walls?.find(wall => wall.id == id)

  }

  getCompany(id){
    return this.filterStructure.companies.find(company => company.id == id)?.code
  }

  getCountry(id){
    return this.filterStructure.countries.find(country => country.id == id)?.code
  }

  getLocation(id){
    return this.filterStructure.locations.find(location => location.id == id)?.code
  }

  userHasCompany():boolean {

    return (localStorage.getItem("idCompany") && localStorage.getItem("idCompany") != 'null')

  }

  userHasCountry(): boolean {

    return (localStorage.getItem("idCountry") && localStorage.getItem("idCountry") != 'null')

  }

  userHasLocation(): boolean {

    return (localStorage.getItem("idCountry") && localStorage.getItem("idLocation") != 'null')

  }

  preselectFilter() {

    if (this.userHasCompany()) {
      this.selectCompany(localStorage.getItem("idCompany"))
    }

    if (this.userHasCountry()) {
      this.selectCountry(localStorage.getItem("idCountry"))
    }

    if (this.userHasLocation()) {
      this.selectLocation(localStorage.getItem("idLocation"))
    }

    if (this.userHasLocation && this.filterStructure.subLocations.length == 1) {
      
      this.selectSubLocation(this.filterStructure.subLocations[0]?.id)

      if (this.filterStructure.walls.length == 1) {
        this.selectWall(this.filterStructure.walls[0])
      }

    }

    //this.saveState()

  }

}

