import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoggingService } from './log.service';
import { throwError } from 'rxjs';

import swal from 'sweetalert2';
import { TranslatorService } from '../translator/translator.service';

export enum RoleType {
  SUPERADMIN,
  ADMIN,
  OPERATOR,
  COLLAUDO_CAMPIONARIO_OPERATIONS,
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  pathInfoVersion = "/bo/info";
  
  constructor (private http: HttpClient, private translatorService: TranslatorService, private translator: TranslatorService, private logger: LoggingService) {}

  login(loginData: Object): any {

    return this.http
    .post<any[]>(environment.restBaseUrl + '/bo/login', loginData, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          const dataResponse = res.data;
          this.logger.log("Service:", dataResponse, 200);
          localStorage.setItem("name", dataResponse.name);
          localStorage.setItem("surname", dataResponse.surname);
          localStorage.setItem("role", dataResponse.role);
          localStorage.setItem("token", dataResponse.token);
          localStorage.setItem("idCompany", dataResponse.idCompany);
          localStorage.setItem("nameCompany", dataResponse.nameCompany);
          if(dataResponse.idRegion) {
            localStorage.setItem("idRegion", dataResponse.idRegion);
            localStorage.setItem("nameRegion", dataResponse.nameRegion);
          }
          if(dataResponse.idSite) {
            localStorage.setItem("idSite", dataResponse.idSite);
            localStorage.setItem("nameSite", dataResponse.nameSite);
          }
          localStorage.setItem("enabled", dataResponse.enabled);
          return res;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          localStorage.clear();
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
      this.logger.log("Service Fail. Error:", error, 200);
        return throwError(error);
      }
    ));
  }

  logout(): any {

    localStorage.clear();

    return this.http
    .post<any[]>(environment.restBaseUrl + '/bo/logout', null, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Logout", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Logout", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));
  }

  forgotPwd(email: string): any {

    localStorage.clear();
    const params: any = {
      email: email,
      language: this.translatorService.browserLanguage()
    };

    return this.http
      .put<any[]>(environment.restBaseUrl + '/bo/user/forgot', params, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
          const res: any = response;

          if (res.outcome.success === true) {
            this.logger.log("Forgot pwd", " SUCCESS", 200);
            return res;
          } else {
            this.logger.log("Forgot pwd", " FAILURE", 200);
            return res;
          }
        }
      ), 
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  changePwd(params: Object): any {

    params['language'] = this.translatorService.browserLanguage();

    return this.http
    .put<any[]>(environment.restBaseUrl + '/bo/account/changepwd', params, this.getRequestOptionArgsWithToken())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Change PWD", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Change PWD", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));
  }

  activate(params: Object): any {

    localStorage.clear();
    params['language'] = this.translatorService.browserLanguage();

    return this.http
    .put<any[]>(environment.restBaseUrl + '/bo/user/activate', params, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Activate", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Activate", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));

  }
  
  renew(params: Object): any {

    localStorage.clear();
    params['language'] = this.translatorService.browserLanguage();

    return this.http
    .put<any[]>(environment.restBaseUrl + '/bo/user/renew', params, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Renew", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Renew", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));

  }

  invite(idUser: number, lang: string): any  {

    let language = lang; //this.translatorService.browserLanguage();

    return this.http
    .put<any[]>(environment.restBaseUrl + '/bo/admin/user/invite/' + idUser + '/' + language, {}, this.getRequestOptionArgsWithToken())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Invite", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Invite", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));
  }

  // Rest Items Service: Read one element (detail)
  getVersion(path) {
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const res: any = response;
        this.logger.log("Infos:", res, 200);
        return res;
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        return throwError(errorResponse.error);
      }
    ));
  }

  getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translator.getLanguageInUse() ?? "",
      })
    };
    return httpOptions;
  }
  
  getRequestOptionArgsWithToken(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translator.getLanguageInUse() ?? "",
        'Authorization': 'Bearer ' + (localStorage.getItem("token") ?? "")
      })
    };
    return httpOptions;
  }


  public isAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    // Check whether the token is expired and return
    // true or false
    return token!=null;
  }



}
