<div class="wrapper">
  <div class="block-center mt-4 wd-xl">
      <!-- START card-->
      <div class="card card-flat">
          <div class="card-header text-center bg-secondary">
              <a href="#">
                  <img class="block-center rounded" src="assets/img/logo-big.png" alt="Image" />
              </a>
          </div>
          <div class="card-body">
              <p class="text-center py-2 font-weight-bold text-primary">{{'base.pws_reset' | translate}}</p>
              <form [formGroup]="valForm" class="form-validate" role="form" name="recoverForm" novalidate="" (submit)="submitForm($event, valForm.value)">
                  <div class="form-group">
                      <div class="input-group with-focus">
                          <input class="form-control border-right-0" type="email" name="email" placeholder="{{'base.email' | translate}}" autocomplete="off" formControlName="email" />
                          <div class="input-group-append">
                              <span class="input-group-text text-muted bg-transparent border-left-0">
                                  <em class="fa fa-envelope"></em>
                                </span>
                            </div>
                        </div>
                        <div class="m-2 text-danger" *ngIf="submitted && valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">{{'base.field_required' | translate}}</div>
                        <div class="m-2 text-danger" *ngIf="submitted && valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">{{'base.field_mail_required' | translate}}</div>
                    </div>
                  <p class="text-left mx-2 text-muted">{{'recover.info' | translate}}</p>
                  <button class="btn btn-danger btn-block mt-4" type="submit">{{'recover.reset' | translate}}</button>
              </form>
          </div>
      </div>
      <!-- END card-->
      <div class="p-3 text-center">
          <span>&copy;</span>
          <span>{{ settings.getAppSetting('year') }}</span>
          <span class="mx-2">-</span>
          <span>{{ settings.getAppSetting('name') }}</span>
          <br/>
          <span>{{ settings.getAppSetting('description') }}</span>
      </div>
  </div>
</div>