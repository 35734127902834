import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { IS1InputSelectItem } from '..';

@Component({
  selector: 's1-input-multiselect',
  templateUrl: './s1-input-multiselect.component.html',
  styleUrls: ['./s1-input-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class S1InputMultiselect implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() enableCheckAll: boolean = true;
  @Input() options: IS1InputSelectItem[];

  dropdownSettings: IDropdownSettings = {};

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'code',
      textField: 'label',
      enableCheckAll: this.enableCheckAll,
      searchPlaceholderText: this.translate.instant('base.search'),
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  
  onSelectAll(items: any) {
    console.log(items);
  }
  
  resetLang(items: any) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'code',
      textField: 'label',
      enableCheckAll: this.enableCheckAll,
      searchPlaceholderText: this.translate.instant('base.search'),
      allowSearchFilter: true
    };
  }

}
