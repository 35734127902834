import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as locales from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { S1ReadableDatePipe } from '../s1-readable-date.pipe';

@Component({
  selector: 's1-input-datepicker',
  templateUrl: './s1-input-datepicker.component.html',
  styleUrls: ['./s1-input-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class S1InputDatepicker {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() labelGrouped: boolean = true;
  @Input() readonly: boolean = false;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() set lang(lang) {
    if (lang) {
      this.bsConfig.locale = lang
    }
  }

  bsConfig = {
    containerClass: 'theme-default',
    dateInputFormat: 'DD/MM/YYYY',
    locale: 'it',
    maxDate: null
  }
  
  constructor(private readableDatePipe: S1ReadableDatePipe) {
    this.defineLocales();
  }
  
  checkIsValid(): boolean {
    // controllo se è stato impostato il maxDate
    if (this.maxDate) {
      this.bsConfig.maxDate = this.maxDate
    }
    return this.control && (this.control.valid || !this.control.touched);
  }
  
  readableDate() {
    return this.control ? this.readableDatePipe.transform(this.control.value) : null
  }
  
  defineLocales() {
    for (const locale in locales) {
      defineLocale(locales[locale].abbr, locales[locale]);
    }
  }
}