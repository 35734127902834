<s1-input-label [label]="label" class="bootstrap-theme" [grouped]="labelGrouped">
  <ng-select class="form-control s1" [placeholder]="placeholder"
    [formControl]="control"
    [items]="itemsList"
    [readonly]="readonly"
    [ngClass]="{'is-invalid' : !checkIsValid() }"
    [bindValue]="bindAllObject ? null : 'code'"
    (clear)="cleared()"
    (change)="changed($event)">
  </ng-select>
</s1-input-label>
