import { Component, Input, ViewEncapsulation } from '@angular/core';

// Componente gestione espansione
@Component({
  selector: '[s1-expandable-row]',
  templateUrl: './s1-expandable-row.component.html',
  styleUrls: ['./s1-expandable-row.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class S1ExpandableRow {

  @Input() expanded: boolean = false

  constructor() { }

}

// Componente contenuto espandibile
@Component({
  selector: '[s1-expandable-row-content]',
  template: '<ng-content *ngIf="master?.expanded"></ng-content>',
})
export class S1ExpandableRowContent {

  @Input() master: S1ExpandableRow;

  constructor() { }

} 
