import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-input-switch',
  templateUrl: './s1-input-switch.component.html',
  styleUrls: ['./s1-input-switch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class S1InputSwitch implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() grouped: boolean = false;
  @Input() readonly: boolean = false;
  @Input() styleClass: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
