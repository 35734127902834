import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISite } from '../models/models';

export interface ISitesSearchParams {
  code: string,
  name: string,
  idCompany: number,
  idRegion: number
}

@Injectable({
  providedIn: 'root'
})
export class SitesService {

  private path = "/bo/sites"

  constructor(private s1HttpClient: S1HttpClientService) { }

  getSites(parameters: ISitesSearchParams, ui: boolean = true): Observable<ISite[]> {
    return this.s1HttpClient.get(this.path, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  createSite(site: ISite): Observable<ISite> {
    return this.s1HttpClient.post(this.path, site, false).pipe(
      map(response => response.data)
    )
  }

  updateSite(site: ISite): Observable<ISite> {
    return this.s1HttpClient.put(this.elementPath(site), site, false).pipe(
      map(response => response.data)
    )
  }

  deleteSite(site: ISite): Observable<boolean> {
    return this.s1HttpClient.delete(this.elementPath(site), false).pipe(
      map(response => response.outcome.success)
    )
  }

  private elementPath(site: ISite): string {
    return this.path + "/" + site.id
  }

}
