import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LoggingService {

  actualLevel: any = environment.production ? 'PROD' : 'ALL';
  logLevels = new Array({ 'ALL': 100, 'DEBUG': 200, 'INFO': 300, 'PROD': 400 });

  log(message: String, obj: any, level: number) {
    if (level >= this.logLevels[0][this.actualLevel]) {
      console.log(message, obj);
    }
  }
}
