import { Injectable } from '@angular/core';

@Injectable()
export class ThemesService {

    styleTag: any;

    constructor() {
        this.createStyle();
    }

    private createStyle() {
        const head = document.head || document.getElementsByTagName('head')[0];
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        this.styleTag.id = 'appthemes';
        head.appendChild(this.styleTag);
    }

}
