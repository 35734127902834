import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-input-range',
  templateUrl: './s1-input-range.component.html',
  styleUrls: ['./s1-input-range.component.scss']
})
export class S1InputRange implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() maxValue: number = 100;
  @Input() grouped: boolean = false;
  @Input() readonly: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
