import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { S1HttpClientService } from './s1-http-client.service';
import { IS1RicercaStandardResult } from './s1-standard-search.service';

export interface IS1Autocomplete {
  valore: string,
  numeroRisultati: number
}

export interface IS1AutocompleteResult extends IS1RicercaStandardResult { }

export interface IS1AutocompleteResults extends Array<IS1RicercaStandardResult> { }

@Injectable({
  providedIn: 'root'
})
export class S1AutocompleteService {

  constructor(private s1HttpClient: S1HttpClientService) { }

  autocomplete(path: string, searchText: string): Observable<IS1AutocompleteResults> {

    const params: IS1Autocomplete = {
      valore: searchText,
      numeroRisultati: 20
    }

    return this.s1HttpClient.post(path, params, false).pipe(
      map(response => { 
        return response.item
      })/*,
      catchError((err: HttpErrorResponse) => {
        return of(false);
      })*/
    )
    
  }

}
