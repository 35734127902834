<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-secondary">
                <a href="#">
                    <img class="block-center rounded" src="assets/img/logo-big.png" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2 font-weight-bold text-primary">{{'base.pws_set' | translate}}</p>
                <form [formGroup]="valForm" class="form-validate" role="form" name="recoverForm" novalidate="" (submit)="submitForm($event, valForm.value)">
                    <div class="form-group">
           
                        <div formGroupName="passwordGroup">
                            <div class="form-group">
                                <div class="input-group with-focus">
                                    <input *ngIf="!safeContext" class="form-control border-right-0" id="id-password" type="password" name="password" formControlName="password" placeholder="{{'base.pwd' | translate}}" [formControl]="valForm.get('passwordGroup.password')" />
                                    <input *ngIf="safeContext" class="form-control border-right-0" id="id-password" type="text" name="password" formControlName="password" placeholder="{{'base.pwd' | translate}}" [formControl]="valForm.get('passwordGroup.password')" />
                                    <div class="input-group-append">
                                        <span class="input-group-text text-muted bg-transparent border-left-0 pointer" (click)="safeContext=!safeContext">
                                            <em *ngIf="!safeContext" class="fa fa-lock"></em>
                                            <em *ngIf="safeContext" class="fa fa-lock-open"></em>
                                        </span>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="valForm.get('passwordGroup.password').hasError('required') && (valForm.get('passwordGroup.password').dirty || valForm.get('passwordGroup.password').touched)">{{'base.field_required' | translate}}</div>
                                <div class="text-danger" *ngIf="valForm.get('passwordGroup.password').hasError('pattern') && (valForm.get('passwordGroup.password').dirty || valForm.get('passwordGroup.password').touched)">{{'base.input_required' | translate}}</div>
                            </div>
                            <div class="form-group">
                                <div class="input-group with-focus">
                                    <input *ngIf="!safeContext" class="form-control border-right-0" type="password" name="confirmPassword" formControlName="confirmPassword" placeholder="{{'base.retype_pwd' | translate}}" [formControl]="valForm.get('passwordGroup.confirmPassword')" />
                                    <input *ngIf="safeContext" class="form-control border-right-0" type="text" name="confirmPassword" formControlName="confirmPassword" placeholder="{{'base.retype_pwd' | translate}}" [formControl]="valForm.get('passwordGroup.confirmPassword')" />
                                    <div class="input-group-append">
                                        <span class="input-group-text text-muted bg-transparent border-left-0 pointer" (click)="safeContext=!safeContext">
                                            <em *ngIf="!safeContext" class="fa fa-lock"></em>
                                            <em *ngIf="safeContext" class="fa fa-lock-open"></em>
                                        </span>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="valForm.get('passwordGroup.confirmPassword').hasError('required') && (valForm.get('passwordGroup.confirmPassword').dirty || valForm.get('passwordGroup.confirmPassword').touched)">{{'base.field_required' | translate}}</div>
                                <div class="text-danger" *ngIf="valForm.get('passwordGroup.confirmPassword').hasError('equalTo')">{{'base.pwd_not_match' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-danger btn-block" type="submit">{{'recover.confirm' | translate}}</button>
                </form>
            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.getAppSetting('year') }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.getAppSetting('name') }}</span>
            <br/>
            <span>{{ settings.getAppSetting('description') }}</span>
        </div>
    </div>
</div>
