import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoggingService } from './log.service';
import { SettingsService } from '../settings/settings.service';

//import { Unit } from '../classes/unit';

export enum FilterAPI {
  data = "/data"
}

export enum DashboardAPI {
  walls = "/dashboard/wall",
  homeChart = "/dashboard/home/chart",
  homePrelievi = "/dashboard/home/prelievi",
  homeUnits = "/dashboard/home/units"
}

export enum CommandsAPI {
  commands = "/commands"
}

export enum UnitAPI {
  launchSignal = "/walls/units/_UNIT-ID_/signal",
  getGraph = "/walls/units/_UNIT-ID_/graph"
}

export enum UserAPI {
  delete = "/admin/user/"
}

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private http: HttpClient, private logger: LoggingService, private router: Router, private settings: SettingsService) {

  }

  //mapPathForUnit(path, unit: Unit) {
  //  return path.replace("_UNIT-ID_", unit.id)
  //}

}

