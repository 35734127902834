import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LoggingService } from '../services/log.service';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

import { RoleType } from '../services/auth.service';

@Injectable()
export class SettingsService {

    private user: any;
    private app: any;
    private layout: any;
    private errorTranslation: any;
    private filterOption: any = new Map();

    sidebarChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private translate: TranslateService, private router: Router, private baseService: AuthService, private logService: LoggingService) {

        this.errorTranslation = this.translate.get('error');

        // User Settings
        // -----------------------------------
        this.user = {
            name: '',
            job: '',
            picture: 'assets/img/user/02.jpg'
        };

        // App Settings
        // -----------------------------------
        this.app = {
            name: 'Soluzione 1',
            description: 'Moncler',
            year: ((new Date()).getFullYear()),
            version: environment.version,
            versionBE: environment.versionBE,
            releaseDate: environment.releaseDate
        };

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    getInfoVersion() {
        this.baseService.getVersion(this.baseService.pathInfoVersion)
            .subscribe((response) => {
                this.setAppSetting('versionBE', response.version);
                this.setAppSetting('releaseDate', response.releaseDate);
                this.logService.log("Infos ", response, 300);
            },
                (error) => {
                    this.logService.log("Error", error, 200);
                }
            );

    }


    sessionExpired() {
        localStorage.clear();
    }

    manageErrorMsg(outcome) {

        this.translate.get('error').subscribe( (text) => {
            this.errorTranslation = text;
        });

        //this.errorTranslation = this.translate.get('error');
        let message = "";

        switch (outcome.code) {
            case '0001':
                message = this.errorTranslation.error_0001;
                break;
            case '0002':
                message = this.errorTranslation.error_0002;
                break;
            case '0003':
                message = this.errorTranslation.bad_credential;
                break;
            case '0004':
                message = this.errorTranslation.pwd_expired;
                break;
            case '0024':
                message = this.errorTranslation.same_pwd;
                break;
            case '0005':
                this.sessionExpired();
                this.router.navigate(["/login/0"]);
                break;
            case '0006':
                message = this.errorTranslation.account_disabled;
                break;
            case '0010':
                message = this.errorTranslation.link_expired;
                break;
            case '0007':
                this.sessionExpired();
                this.router.navigate(["/login/0"]);
                message = this.errorTranslation.auth_required;
                break;
            default:
                message = this.errorTranslation.generic_error;
                break;
        }
        return message;
    }

    setFilterFromSidebar( key: string , value: string ) {
        this.filterOption[key] = value;
        localStorage.setItem( 'filterOption' , JSON.stringify(this.filterOption) );
    }

    initActiveFilterOfSidebar() {
        
    }

    getActiveFiltersOfSidebar() {
        var result = JSON.parse(localStorage.getItem( 'filterOption' ));
        return result;
    }

    getCompany() {
        return this.isSuperAdmin() ? 
                    this.getFilterOfSidebarByName("company") :
                    {'code': localStorage.getItem("idCompany"), 'label': localStorage.getItem("nameCompany") };
    }

    getRegion() {
        return this.isOperatore() ? 
                    {'code': localStorage.getItem("idRegion"), 'label': localStorage.getItem("nameRegion")} :
                    null;
    }

    getSite() {
        return this.isOperatore() ? 
                    {'code': localStorage.getItem("idSite"), 'label': localStorage.getItem("nameSite")} :
                    null;
    }

    getFilterOfSidebarByName( key ) {
        if( this.getActiveFiltersOfSidebar() ) {
            return this.getActiveFiltersOfSidebar()[key];
        }
    }

    deleteFilterByName( key: string ) : void {
        if( this.getActiveFiltersOfSidebar() ) {
            var result = JSON.parse(localStorage.getItem( 'filterOption' ));
            delete result[key];
            this.filterOption = result;
            localStorage.setItem( 'filterOption' , JSON.stringify(this.filterOption) );
        }
        return;
    }

    onSidebarFilterChange() : void {
        this.sidebarChanged.next(true);
    }

    onSidebarFilterDelete() : void {
        this.sidebarChanged.next(true);
    }

    isSuperAdmin() : boolean {
        var _role = localStorage.getItem('role')
        if( _role && _role == RoleType[RoleType.SUPERADMIN] ) {
            return true;
        } else {
            return false;
        }
    }

    isAdmin() : boolean {
        var _role = localStorage.getItem('role')
        if( _role && _role == RoleType[RoleType.ADMIN] ) {
            return true;
        } else {
            return false;
        }
    }

    isOperatore() : boolean {
        var _role = localStorage.getItem('role')
        if( _role && _role == RoleType[RoleType.OPERATOR] ) {
            return true;
        } else {
            return false;
        }
    }

    isCollaudoCampionarioOperations() : boolean {
        var _role = localStorage.getItem('role')
        if( _role && _role == RoleType[RoleType.COLLAUDO_CAMPIONARIO_OPERATIONS] ) {
            return true;
        } else {
            return false;
        }
    }
    
    getUserRole() : void | string {
        var _role = localStorage.getItem('role');
        if( _role ) {
            return _role;
        }
    }

}
