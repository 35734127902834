import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '@davidda/ngx-custom-validators';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { S1UIService } from '@app/s1';
import { LoggingService } from '@app/core/services/log.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  valForm: UntypedFormGroup;
  submitted = false;

  constructor(public settings: SettingsService, fb: UntypedFormBuilder, private logger: LoggingService, private authService: AuthService, private router: Router, private ui: S1UIService) {
    this.valForm = fb.group({
      'email': [null, Validators.compose([Validators.required, CustomValidators.email])]
    });
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    this.submitted = true;
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {

      this.ui.showSpinner();
      this.authService.forgotPwd(this.valForm.controls.email.value).subscribe(response => {
        this.ui.closeSpinner();
        
        if (response?.outcome?.success === true) {
          this.ui.showSuccessToast('base.sent_mail_password_reset');
          this.router.navigate(['login']);
        } else {
          this.ui.showErrorToast(this.settings.manageErrorMsg(response.outcome));
        }
      },
      (error) => {
        this.logger.log("Error", error, 200);
        this.ui.closeSpinner();
        this.ui.showErrorToast('error.generic_error');
      });

    }
  }

  ngOnInit(): void {
  }

}
